
  import { Component, Ref, Mixins } from "vue-property-decorator";
  import { Getter, Action } from "vuex-class";
  import { FormNumber, FormBase, FormMoney, FormInput, FormSelect, FormCheckbox, FormTextarea, FormSubmit, FormYesNo, FormDate, FormWysiwyg } from "@/components/forms";
  import { HasFormErrors } from "@/mixins/has-form-errors";
  import { HasPropertyOptions } from "@/mixins/has-property-options";
  import { set, cloneDeep, merge, isNil } from "lodash";
  import { omitDeepBy } from "lodash-omitdeep";
  import { format } from "fecha";

  const scrollIntoView = require("scroll-into-view");
  import { ValidationProvider } from "vee-validate";
  import NavScroll from "navscroll";

  import { Orientations } from "@/enums/property";

  @Component({
    components: {
      InputRadio: () => import("@/components/forms/property-radio.vue"),
      InputSimpleRadio: () => import("@/components/forms/radio.vue"),

      HeaderPart: () => import("./parts/tablet/header.vue"),
      TypePart: () => import("./parts/tablet/types.vue"),
      SubtypePart: () => import("./parts/tablet/subtypes.vue"),
      CadastralIncomePart: () => import("./parts/tablet/cadastral-income.vue"),
      ZonesPart: () => import("./parts/tablet/zones.vue"),
      WellnessPart: () => import("./parts/tablet/wellness.vue"),
      OtherPart: () => import("./parts/tablet/other.vue"),

      BuildingStatePart: () => import("./parts/tablet/building-state.vue"),
      BuildingTypePart: () => import("./parts/tablet/building-type.vue"),
      BuildingPlanPart: () => import("./parts/tablet/building-plan.vue"),

      EquipmentSpecializedPart: () => import("./parts/tablet/equipment-specialized.vue"),
      EquipmentKitchenPart: () => import("./parts/tablet/equipment-kitchen.vue"),
      EquipmentSecurityPart: () => import("./parts/tablet/equipment-security.vue"),
      EquipmentBathroomPart: () => import("./parts/tablet/equipment-bathroom.vue"),

      ConstructionFacadePart: () => import("./parts/tablet/construction-facade.vue"),
      ConstructionRoofPart: () => import("./parts/tablet/construction-roof.vue"),
      ConstructionExteriorJoineryPart: () => import("./parts/tablet/construction-exterior-joinery.vue"),

      InstallationHeatingPart: () => import("./parts/tablet/installation-heating.vue"),
      InstallationVentilationPart: () => import("./parts/tablet/installation-ventilation.vue"),
      InstallationElectricalPart: () => import("./parts/tablet/installation-electrical.vue"),
      InstallationRollerShuttersPart: () => import("./parts/tablet/installation-roller-shutters.vue"),
      InstallationGarageDoorPart: () => import("./parts/tablet/installation-garage-door.vue"),

      OtherSalesConditions: () => import("./parts/tablet/other-sales-conditions.vue"),

      ValidationProvider,
      FormNumber,
      FormBase,
      FormInput,
      FormDate,
      FormYesNo,
      FormMoney,
      FormSelect,
      FormTextarea,
      FormCheckbox,
      FormWysiwyg,
      FormSubmit,
    },
  })
  export default class PagePropertyCreate extends Mixins(HasFormErrors, HasPropertyOptions) {
    @Getter("auth/me") account!: CurrentAccount;
    @Getter("property/viewing") property!: Property;

    @Action("property/update") updateProperty!: (payload: PropertyUpdatePayload) => Promise<Property>;
    @Action("property/read") readProperty!: (payload: { activity_id: number }) => Promise<Property>;

    @Ref() form!: FormClass;

    cloning = true;

    activity_id = Number(this.$route.params.id);

    address: Address = {
      street: "",
      number: "",
      additional: "",
      city: "",
      zip: "",
      country: "",
    };

    showSyndicusInput: boolean | null = null;

    priceOrRange: "FIXED" | "RANGE" | null = null;

    description: string | null = null;

    unsavedChanges: boolean = false;

    orientationOptions = [
      { value: Orientations.Nort, label: "Noord" },
      { value: Orientations.NortEast, label: "Noordoosten" },
      { value: Orientations.East, label: "Oost" },
      { value: Orientations.SouthEast, label: "Zuidoosten" },
      { value: Orientations.South, label: "Zuid" },
      { value: Orientations.SouthWest, label: "Zuidwesten" },
      { value: Orientations.West, label: "West" },
      { value: Orientations.NortWest, label: "Noordwesten" },
    ];

    vatOptions = [
      { value: 6, label: "6% BTW" },
      { value: 21, label: "21% BTW" },
    ];

    gScoreOptions = [
      { value: "A", label: "A" },
      { value: "B", label: "B" },
      { value: "C", label: "C" },
      { value: "D", label: "D" },
    ];

    pScoreOptions = [
      { value: "A", label: "A" },
      { value: "B", label: "B" },
      { value: "C", label: "C" },
      { value: "D", label: "D" },
    ];

    epcLabelOptions = [
      { value: "A+", label: "A+" },
      { value: "A", label: "A" },
      { value: "B", label: "B" },
      { value: "C", label: "C" },
      { value: "D", label: "D" },
      { value: "E", label: "E" },
      { value: "F", label: "F" },
      { value: "G", label: "G" },
    ];

    get offset() {
      //@ts-ignore
      if (this.$root.breakpoints.md) {
        return 77;
      }
      return 70;
    }

    get suggestedLivableArea() {
      if (!this.payload.rooms) {
        return {
          all: 0,
          floor_0: 0,
          floor_1: 0,
          floor_2: 0,
          floor_3: 0,
        };
      }
      let total = {
        all: 0,
        floor_0: 0,
        floor_1: 0,
        floor_2: 0,
        floor_3: 0,
      };

      this.payload
        .rooms!.filter((room) => room.is_livable_area)
        .forEach((room) => {
          total.all += Number(room?.area) ?? 0;
          if (Number(room?.floor) === 0) {
            total.floor_0 += Number(room?.area) ?? 0;
          } else if (Number(room?.floor) === 1) {
            total.floor_1 += Number(room?.area) ?? 0;
          } else if (Number(room?.floor) === 2) {
            total.floor_2 += Number(room?.area) ?? 0;
          } else if (Number(room?.floor) === 3) {
            total.floor_3 += Number(room?.area) ?? 0;
          }
        });

      return total;
    }

    get lastChangeDate() {
      if (!this.property) {
        return "";
      }

      return format(new Date(this.property.updated_at), "DD-MM-YYYY [om] HH:mm");
    }

    async mounted() {
      try {
        await this.readProperty({ activity_id: this.activity_id });

        await this.cloneProperty();

        let navWrapper = document.getElementById("nav-wrapper");
        let options = { container: "#scroll-container", activeClass: "text-secondary-500" };

        this.description = this.property.description;

        // Call the following code when both the nav wrapper and the scrolling container are present in the DOM
        // This will register the scrollTo fn as the onlick handler on the nav items and also register the onScroll handler on the scrolling container. It will then observe for new/removed nav items inside the wrapper.
        NavScroll.initObserver(navWrapper, ".item", options);

        if (this.$route.hash) {
          const goTo = document.querySelector(this.$route.hash);
          if (goTo) {
            scrollIntoView(goTo);
          }
        }
      } catch (e) {
        this.errorResponse = this.formatErrors(e);
      }
    }

    submit() {
      const payload: PropertyUpdatePayload = {
        property_id: this.property.id,
        address: this.address,
        activity_id: this.activity_id,
        description: this.description,
        characteristics: { ...this.payload, rooms: this.payload.rooms!.filter((room) => room.type !== "") },
      };

      this.updateProperty(payload)
        .then((property: Property) => {
          this.$toast.open({ message: "Pandgegevens aangevuld!", type: "success" });
        })
        .catch((e) => {
          this.errorResponse = this.formatErrors(e);

          const invalidElems = document.querySelectorAll(".error-holder");
          if (invalidElems.length) {
            scrollIntoView(invalidElems[0]);
          }
          this.$toast.open({ message: "Oops er ging iets mis", type: "error" });
        })
        .finally(() => {
          if (this.form) {
            this.form.reset();
          }
        });
    }

    addRoom(type: string = "") {
      this.payload.rooms!.push({
        type: type,
        title: "",
        area: 0,
        floor: 0,
        is_livable_area: true,
        renovation_needed: false,
        renovation_year: "",
        note: "",
      });
    }

    removeRoom(index: number) {
      this.payload.rooms!.splice(index, 1);
    }

    groundSurfaceChanged() {
      if (!this.payload.ground_area) {
        return;
      }

      this.payload.cadastral_surface = this.payload.ground_area;
    }

    toggleArrayPropertyValue(property: Array<string> | null, value: string) {
      if (property == null) {
        property = [value];
        return;
      }

      const index = property.findIndex((option: string) => option === value);

      if (index === -1) {
        property.push(value);
      } else {
        property.splice(index, 1);
      }
    }

    disableFields(...fields: string[]) {
      fields.forEach((field) => set(this, field, null));
    }

    scrollToFirstError() {
      const invalidElems = document.querySelectorAll(".invalid");

      if (invalidElems.length) {
        scrollIntoView(invalidElems[0]);
      }
    }

    handleInvalidSubmit() {
      this.$toast.open({ message: `${this.form.allErrors!.length} veld${this.form.allErrors!.length > 1 ? "en zijn" : " is"}  onjuist ingevuld` as string, type: "error" });
      this.scrollToFirstError();
    }

    handleCancel() {
      if (this.$route.query && this.$route.query.redirect && typeof this.$route.query.redirect === "string" && ["dossier-property-viewer"].includes(this.$route.query.redirect)) {
        this.$router.push({ name: `${this.$route.query.redirect}`, params: { id: `${this.activity_id}` }, hash: this.$route.hash });
        return;
      }

      this.$router.push({ name: "dossier-detail", params: { id: `${this.activity_id}` } });
    }

    handleRoomTypeChanged(index: number, event: string) {
      if (!this.payload.rooms || !this.payload.rooms[index]) {
        return;
      }
      const type = this.roomTypes.find((type: { value: string; label: string }) => type.value === event);
      if (type) {
        this.payload.rooms![index].title = type.label;
      }
      if (event !== "KEUKEN") {
        this.payload.rooms![index].kitchen_type = null;
      }
      if (event !== "BATHROOM") {
        this.payload.rooms![index].bathroom_type = null;
      }
    }

    maybeChangeGarageCount() {
      if (this.payload.features.has_garage === false) {
        this.payload.features.garage_count = null;
      }
    }
    maybeChangeParkingCount() {
      if (this.payload.features.has_parking === false) {
        this.payload.features.inside_parking_count = null;
        this.payload.features.outside_parking_count = null;
      }

      if (this.payload.features.has_inside_parking === false) {
        this.payload.features.inside_parking_count = null;
      }

      if (this.payload.features.has_outside_parking === false) {
        this.payload.features.outside_parking_count = null;
      }
    }

    correctNullableArrayProperties() {
      if (this.payload.zone == null) {
        this.payload.zone = [];
      }
      if (this.payload.installation) {
        if (this.payload.installation.heating) {
          if (this.payload.installation.heating.centralized_heating == null) {
            this.payload.installation.heating.centralized_heating = [];
          }
          if (this.payload.installation.heating.centralized_heating_fuel == null) {
            this.payload.installation.heating.centralized_heating_fuel = [];
          }
          if (this.payload.installation.heating.decentralized_heating == null) {
            this.payload.installation.heating.decentralized_heating = [];
          }
          if (this.payload.installation.heating.water_heating == null) {
            this.payload.installation.heating.water_heating = [];
          }
          if (this.payload.installation.roller_shutters.material == null) {
            this.payload.installation.roller_shutters.material = [];
          }
        }
      }
      if (this.payload.construction) {
        if (this.payload.construction.facade.material == null) {
          this.payload.construction.facade.material = [];
        }
        if (this.payload.construction.roof.material == null) {
          this.payload.construction.roof.material = [];
        }
        if (this.payload.construction.exterior_joinery.windows == null) {
          this.payload.construction.exterior_joinery.windows = [];
        }
        if (this.payload.construction.exterior_joinery.material == null) {
          this.payload.construction.exterior_joinery.material = [];
        }
        if (this.payload.construction.wall_finishing.material == null) {
          this.payload.construction.wall_finishing.material = [];
        }
        if (this.payload.construction.ceiling_finishing.material == null) {
          this.payload.construction.ceiling_finishing.material = [];
        }
        if (this.payload.construction.floor_finishing.material == null) {
          this.payload.construction.floor_finishing.material = [];
        }
        if (this.payload.construction.mezzanine.material == null) {
          this.payload.construction.mezzanine.material = [];
        }
      }
    }

    async cloneProperty() {
      this.cloning = true;

      this.address = cloneDeep(this.property.address);

      if (this.property.characteristics) {
        // Clone and ignore null values to fill in defaults
        this.payload = merge(this.payload, omitDeepBy(cloneDeep(this.property.characteristics), isNil));

        this.correctNullableArrayProperties();

        if (this.payload.connections?.network_connection_available === null) {
          this.payload.connections.network_connection_available = this.payload.connections?.network_provider !== null;
        }

        this.showSyndicusInput = this.payload.building.syndicus_monthly_rate !== null || this.payload.building.syndicus_information !== null;

        this.priceOrRange =
          this.payload.sale.price_estimation !== null ? "FIXED" : this.payload.sale.price_range_min !== null || this.payload.sale.price_range_max !== null ? "RANGE" : null;
      }

      this.cloning = false;
    }

    maybeAddRooms() {
      if (this.payload.features.bedrooms) {
        const total = Number(this.payload.features.bedrooms);
        let rooms = (this.payload.rooms ?? []).filter((r) => r.type === "BEDROOM");
        if (total > rooms.length) {
          for (let i = this.payload.rooms!.length; i < total; i++) {
            this.addRoom("BEDROOM");
          }
        }
      }
      if (this.payload.features.bathrooms) {
        const total = Number(this.payload.features.bathrooms);
        let rooms = (this.payload.rooms ?? []).filter((r) => r.type === "BATHROOM");
        if (total > rooms.length) {
          for (let i = rooms.length; i < total; i++) {
            this.addRoom("BATHROOM");
          }
        }
      }
    }
  }
